// Context to manage the edition of Dispatch Scheduler Jobs
import React, { useContext, useEffect, useRef, useState } from 'react';

import { OperatorTruckIndexRecord } from '@isheepdog/operator-search';
import { useList } from '@uidotdev/usehooks';

import { omit } from 'lodash';

import Confirm from '@/components/Modal/Confirm';

import { useAuthContext } from '@/lib/context/AuthContext';

import {
  FakeDocumentSnapshot,
  createFakeDocument,
} from '@/lib/firebase/db/fake';
import { getOperatorTrucksFromJobAssignments } from '@/lib/firebase/db/helpers/operatorTruckHelpers';
import {
  JobAssignment,
  JobAssignmentDoc,
  JobDoc,
  OperatorTruckDoc,
} from '@/lib/firebase/db/metaTypes';
import {
  JobAssignmentsBatchUpdateOperation,
  jobAssignmentsBatchUpdate,
} from '@/lib/firebase/httpsCallable';

import { getCanUserEditDispatchSchedule } from '@/lib/helpers/scheduler';
import { isAssigner } from '@/lib/helpers/userRoles';

const MIN_TRUCKS_TO_ASSIGN = 1;

type SelectedJob = JobDoc | null;

type SelectedGroup = number | null;

export type FakeOrRealJobAssignmentDoc =
  | JobAssignmentDoc
  | FakeDocumentSnapshot<JobAssignment>;

type AssignmentsUpdateType = 'assign' | 'remove' | 'move';

export type AssignmentsUpdateOperation = {
  jobId: string;
  type: AssignmentsUpdateType;
  // Assign Operator params
  userId?: string;
  groupIndex?: number;
  trucksNumberToReassign?: number;
  jobAssignmentDoc?: FakeOrRealJobAssignmentDoc;
  // Remove Operator params
  jobAssignmentId?: string;
};

interface EditSchedulerContextType {
  canUserEditDispatchSchedule: boolean;
  selectedJob: SelectedJob;
  onSelectJob: (jobDoc: SelectedJob) => void;
  selectedJobAssignments: JobAssignmentDoc[];
  selectedJobOperatorTrucks: (OperatorTruckDoc | OperatorTruckIndexRecord)[];
  onChangeSelectedJobAssignments: (jobAssignments: JobAssignmentDoc[]) => void;
  selectedGroup: SelectedGroup;
  onSelectGroup: (group: SelectedGroup) => void;
  onSelectOperatorToAssign: (operatorTruck: OperatorTruckIndexRecord) => void;
  onSelectOperatorToRemove: (jobAssignmentDoc: JobAssignmentDoc) => void;
  onSelectOperatorToMove: (
    jobAssignmentDoc: JobAssignmentDoc,
    groupIndex: number
  ) => void;
  operationsRecordList: AssignmentsUpdateOperation[];
  isLoading: boolean;
  onConfirmAssignmentChanges: () => Promise<unknown>;
  onRevertAssignmentChanges: () => void;
}

export const EditSchedulerContext =
  React.createContext<EditSchedulerContextType>({
    canUserEditDispatchSchedule: false,
    selectedJob: null,
    onSelectJob: () => null,
    selectedJobAssignments: [],
    selectedJobOperatorTrucks: [],
    onChangeSelectedJobAssignments: () => null,
    selectedGroup: null,
    onSelectGroup: () => null,
    onSelectOperatorToAssign: () => null,
    onSelectOperatorToRemove: () => null,
    onSelectOperatorToMove: () => null,
    operationsRecordList: [],
    isLoading: false,
    onConfirmAssignmentChanges: () => Promise.resolve(),
    onRevertAssignmentChanges: () => null,
  });

export const useEditSchedulerContext = () => useContext(EditSchedulerContext);

export const EditSchedulerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userDoc } = useAuthContext();
  const [canUserEditDispatchSchedule, setCanUserEditDispatchSchedule] =
    useState(false);
  const [selectedJob, setSelectedJob] = useState<SelectedJob>(null);
  const [selectedJobAssignments, setSelectedJobAssignments] = useState<
    JobAssignmentDoc[]
  >([]);
  const [selectedJobOperatorTrucks, setSelectedJobOperatorTrucks] = useState<
    (OperatorTruckDoc | OperatorTruckIndexRecord)[]
  >([]);
  const [selectedGroup, setSelectedGroup] = useState<SelectedGroup>(null);
  const selectedGroupRef = useRef<SelectedGroup>(null);

  const [operatorToAssign, setOperatorToAssign] =
    useState<OperatorTruckIndexRecord | null>(null);
  const [trucksNumberToReassign, setTrucksNumberToReassign] =
    useState(MIN_TRUCKS_TO_ASSIGN);
  const selectedOperatorRef = useRef<OperatorTruckIndexRecord | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [operationsRecordList, manageOperations] =
    useList<AssignmentsUpdateOperation>([]);

  useEffect(() => {
    selectedGroupRef.current = selectedGroup;
  }, [selectedGroup]);

  useEffect(() => {
    selectedOperatorRef.current = operatorToAssign;
  }, [operatorToAssign]);

  useEffect(() => {
    if (!userDoc) return;
    setCanUserEditDispatchSchedule(getCanUserEditDispatchSchedule(userDoc));

    // Add onClick handler to document body to clear selected job when clicked outside
    document.body.addEventListener('click', (event) => {
      // Do not clear selected job if a group or operator is already selected
      if (
        selectedGroupRef.current !== null ||
        selectedOperatorRef.current !== null ||
        !event.target
      )
        return;
      const target = event.target as Element;
      const isJobCardClicked =
        !!target.closest('.job-card') ||
        !!target.closest('.operator-search-widget') ||
        !!target.closest('.action-btn-panel');
      if (!isJobCardClicked) {
        return setSelectedJob(null);
      }
    });
  }, [!!userDoc]);

  useEffect(() => {
    if (selectedJob === null) {
      setSelectedGroup(null);
    }
  }, [selectedJob]);

  function onChangeSelectedJobAssignments(jobAssignments: JobAssignmentDoc[]) {
    getOperatorTrucksFromJobAssignments(jobAssignments).then(
      (operatorTrucks) => {
        setSelectedJobAssignments(jobAssignments);
        setSelectedJobOperatorTrucks(operatorTrucks);
      }
    );
  }

  function onSelectOperatorToAssign(operatorHit: OperatorTruckIndexRecord) {
    // Check that same operator was not selected twice
    const [lastOperation] = operationsRecordList.slice(-1);
    if (
      !!lastOperation &&
      lastOperation.type === 'assign' &&
      lastOperation.userId === operatorHit.operator.userId
    ) {
      return console.log(
        `Operator ${operatorHit.operator.nickname} was selected twice`
      );
    }
    if (isAssigner(operatorHit)) {
      setOperatorToAssign(operatorHit);
      setTrucksNumberToReassign(MIN_TRUCKS_TO_ASSIGN);
    } else {
      // When non assigner is selected add it to job without prompting
      onConfirmAssignOperator(operatorHit);
    }
  }

  function onConfirmAssignOperator(operator?: OperatorTruckIndexRecord) {
    const selectedOperator = operator || operatorToAssign;
    if (!selectedJob || !selectedOperator || selectedGroup === null) return;
    // Check that the same operator was not previously removed from the same group
    const removeSameOperatorOperationIndex = operationsRecordList.findIndex(
      (operation) =>
        operation.jobId === selectedJob.id &&
        operation.type === 'remove' &&
        // TODO: Consider case when operator is moved to another group and then removed
        operation.jobAssignmentDoc?.get('groupIndex') === selectedGroup &&
        selectedOperator.operator.userId ===
          operation.jobAssignmentDoc.get('receiver.user.id') &&
        operation.jobAssignmentDoc.get('receiver.trucksNumberToReassign') ==
          trucksNumberToReassign // Check that trucksNumberToReassign is not being updated
    );
    if (removeSameOperatorOperationIndex >= 0) {
      manageOperations.removeAt(removeSameOperatorOperationIndex);
    } else {
      manageOperations.push({
        jobId: selectedJob.id,
        type: 'assign',
        userId: selectedOperator.operator.userId,
        groupIndex: selectedGroup,
        trucksNumberToReassign: trucksNumberToReassign,
        jobAssignmentDoc: createFakeDocument<JobAssignment>(
          `${selectedOperator.operator.userId}-${selectedJob.id}-${selectedGroup}`,
          {
            groupIndex: selectedGroup,
            receiver: {
              company: {
                id: selectedOperator.company.companyId,
                name: selectedOperator.company.name,
              },
              user: {
                id: selectedOperator.operator.userId,
                ...selectedOperator.operator,
              },
              startDatetimeDifferenceMs: 0,
              status: 'not notified',
              trucksNumberToReassign,
              trucksAssignedNumber: 0,
            },
          }
        ),
      });
    }
    setOperatorToAssign(null);
    // Add selected operator to list of OperatorTrucks so it's
    // immediately excluded from search results list
    setSelectedJobOperatorTrucks(
      selectedJobOperatorTrucks.concat(selectedOperator)
    );
  }

  function onCancelAssignOperator() {
    setOperatorToAssign(null);
  }

  function onSelectOperatorToRemove(jobAssignmentDoc: JobAssignmentDoc) {
    if (!selectedJob) return;
    // Check if operator being removed was previously moved (so it should delete the "move" operation)
    const previousMoveOperationIndex = operationsRecordList.findIndex(
      (operation) =>
        operation.jobId === selectedJob.id &&
        operation.type === 'move' &&
        operation.jobAssignmentId === jobAssignmentDoc.id
    );
    if (previousMoveOperationIndex >= 0) {
      manageOperations.removeAt(previousMoveOperationIndex);
    }
    // Check that the same operator being removed was not previously assigned
    const assignSameOperatorOperationIndex = operationsRecordList.findIndex(
      (operation) =>
        operation.type === 'assign' &&
        operation.groupIndex === jobAssignmentDoc.get('groupIndex') &&
        operation.jobAssignmentDoc?.get('receiver.user.id') ===
          jobAssignmentDoc.get('receiver.user.id')
    );
    if (assignSameOperatorOperationIndex >= 0) {
      manageOperations.removeAt(assignSameOperatorOperationIndex);
    } else {
      manageOperations.push({
        jobId: selectedJob.id,
        type: 'remove',
        jobAssignmentId: jobAssignmentDoc.id,
        jobAssignmentDoc,
      });
    }
  }

  function onSelectOperatorToMove(
    jobAssignmentDoc: JobAssignmentDoc,
    groupIndex: number
  ) {
    if (!selectedJob) return;
    // Check if there's a previous move operation for same assignment
    const previousMoveOperationIndex = operationsRecordList.findIndex(
      (operation) =>
        operation.jobId === selectedJob.id &&
        operation.type === 'move' &&
        operation.jobAssignmentId === jobAssignmentDoc.id
    );
    if (previousMoveOperationIndex >= 0) {
      manageOperations.removeAt(previousMoveOperationIndex);
      // Check if the new group is the same as it was originally moved from
      const originalJobAssignment = selectedJobAssignments.find(
        (originalJobAssignment) =>
          originalJobAssignment.id === jobAssignmentDoc.id
      );
      const isGoingBackToInitialGroup =
        originalJobAssignment?.get('groupIndex') === groupIndex;
      // If operator is being moved back to its original group return early
      // after removing last operation as there's no need to add a new one
      if (isGoingBackToInitialGroup) return;
    }
    // Check if operator being moved is a new assignment
    const assignOperationIndex = operationsRecordList.findIndex(
      (operation) =>
        operation.jobId === selectedJob.id &&
        operation.type === 'assign' &&
        operation.jobAssignmentDoc?.id === jobAssignmentDoc.id
    );
    const isNewAssignedOperator = assignOperationIndex >= 0;
    if (isNewAssignedOperator) {
      const assignOperationToUpdate =
        operationsRecordList[assignOperationIndex];
      if (!assignOperationToUpdate) return;
      // Update group index of existing assign operation
      manageOperations.updateAt(assignOperationIndex, {
        ...assignOperationToUpdate,
        groupIndex,
        jobAssignmentDoc: createFakeDocument<JobAssignment>(
          `${jobAssignmentDoc.get('receiver.user.id')}-${selectedJob.id}-${groupIndex}`,
          {
            ...(jobAssignmentDoc.data() || {}),
            groupIndex,
          }
        ),
      });
    } else {
      manageOperations.push({
        jobId: selectedJob.id,
        type: 'move',
        jobAssignmentId: jobAssignmentDoc.id,
        groupIndex,
        userId: jobAssignmentDoc.get('receiver.user.id'),
        jobAssignmentDoc,
      });
    }
  }

  function onConfirmAssignmentChanges() {
    setIsLoading(true);
    setSelectedGroup(null);
    setSelectedJob(null);
    const operations: JobAssignmentsBatchUpdateOperation[] =
      operationsRecordList
        .map((operation) => omit(operation, 'jobAssignmentDoc'))
        .map((operation) => {
          if ('groupIndex' in operation) {
            return {
              ...operation,
              // Ensure groupIndex is sent as string
              groupIndex: `${operation.groupIndex}`,
            };
          }
          return operation;
        });
    return jobAssignmentsBatchUpdate({
      operations,
    }).finally(() => {
      setIsLoading(false);
      manageOperations.clear();
    });
  }

  function onRevertAssignmentChanges() {
    setSelectedGroup(null);
    setSelectedJob(null);
    manageOperations.clear();
  }

  const contextValue: EditSchedulerContextType = {
    canUserEditDispatchSchedule,
    selectedJob,
    onSelectJob: setSelectedJob,
    selectedJobAssignments,
    selectedJobOperatorTrucks,
    onChangeSelectedJobAssignments,
    selectedGroup,
    onSelectGroup: setSelectedGroup,
    onSelectOperatorToAssign,
    onSelectOperatorToRemove,
    onSelectOperatorToMove,
    operationsRecordList,
    isLoading,
    onConfirmAssignmentChanges,
    onRevertAssignmentChanges,
  };
  return (
    <EditSchedulerContext.Provider value={contextValue}>
      {children}
      <Confirm
        content={
          !!selectedJob && (
            <ConfirmAssignOperatorContent
              operatorToAssign={operatorToAssign}
              selectedJob={selectedJob}
              trucksNumberToReassign={trucksNumberToReassign}
              onChangeTrucksNumberToReassign={setTrucksNumberToReassign}
            />
          )
        }
        onConfirm={() => onConfirmAssignOperator()}
        onCancel={onCancelAssignOperator}
        isOpen={!!operatorToAssign}
        isLoading={isLoading}
      />
    </EditSchedulerContext.Provider>
  );
};

function ConfirmAssignOperatorContent({
  selectedJob,
  operatorToAssign,
  trucksNumberToReassign,
  onChangeTrucksNumberToReassign,
}: {
  selectedJob: JobDoc;
  operatorToAssign: OperatorTruckIndexRecord | null;
  trucksNumberToReassign: number;
  onChangeTrucksNumberToReassign: (trucks: number) => void;
}) {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const trucks = +ev.target.value;
    const totalTrucks =
      operatorToAssign?.company.totalTrucks || MIN_TRUCKS_TO_ASSIGN;
    if (trucks > 0 && trucks <= totalTrucks) {
      onChangeTrucksNumberToReassign(trucks);
    }
  };

  if (!operatorToAssign) {
    return null;
  }

  return (
    <div className="text-center">
      <h3>{`Add "${operatorToAssign.operator.nickname}" to ${selectedJob?.get('name')}`}</h3>
      {isAssigner(operatorToAssign as unknown as OperatorTruckDoc) && (
        <div className="flex items-center justify-evenly">
          <div className="mr-2">{'How many trucks should be assigned?'}</div>
          <input
            type="number"
            className="input input-bordered w-24"
            min={MIN_TRUCKS_TO_ASSIGN}
            max={operatorToAssign.company.totalTrucks}
            value={trucksNumberToReassign}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}

/**
 * Updates a list of job assignments based on a series of operations (assign, remove, move).
 * This function processes a list of operations (assign, remove, move) applied to job assignments for a specific job.
 * It filters the operations for the given job ID, applies these operations to the initial list of job assignments,
 * and returns an updated list. This updated list may include both real and fake (`FakeDocumentSnapshot`) job
 * assignment documents, reflecting the changes specified by the operations. The function supports adding new
 * assignments, removing existing ones, or moving assignments to a different group within the job.
 *
 * @param {string} jobId - The ID of the job for which job assignments are being updated.
 * @param {JobAssignmentDoc[]} jobAssignments - The initial list of job assignments for the job.
 * @param {AssignmentsUpdateOperation[]} operationsRecordList - A list of operations to apply to the job assignments.
 * @returns {FakeOrRealJobAssignmentDoc[]} An updated list of job assignments after applying the specified operations.
 */
export function parseJobAssignmentsBasedOnOperationsRecord(
  jobId: string,
  jobAssignments: JobAssignmentDoc[],
  operationsRecordList: AssignmentsUpdateOperation[]
) {
  const jobOperationsRecordList = operationsRecordList.filter(
    (operation) => operation.jobId === jobId
  );
  let parsedJobAssignments: FakeOrRealJobAssignmentDoc[] = [...jobAssignments];
  jobOperationsRecordList.forEach((operation) => {
    const { type, jobAssignmentDoc, jobAssignmentId, groupIndex } = operation;
    if (type === 'assign' && !!jobAssignmentDoc) {
      parsedJobAssignments = parsedJobAssignments.concat(jobAssignmentDoc);
    } else if (type === 'remove' && !!jobAssignmentId) {
      const jobAssignmentToRemoveId = jobAssignmentId;
      parsedJobAssignments = parsedJobAssignments.filter(
        (jobAssignmentDoc) => jobAssignmentDoc.id !== jobAssignmentToRemoveId
      );
    } else if (type === 'move' && !!jobAssignmentId && groupIndex !== null) {
      const jobAssignmentToMoveIndex = parsedJobAssignments.findIndex(
        (jobAssignmentDoc) => jobAssignmentDoc.id === jobAssignmentId
      );
      if (jobAssignmentToMoveIndex < 0) return;
      const jobAssignmentToMove = parsedJobAssignments[
        jobAssignmentToMoveIndex
      ] as JobAssignmentDoc;
      const receiver = jobAssignmentToMove.get('receiver');

      if (!receiver || !receiver.user || !receiver.company) return;

      parsedJobAssignments = [
        ...parsedJobAssignments.slice(0, jobAssignmentToMoveIndex),
        createFakeDocument<JobAssignment>(jobAssignmentToMove.id, {
          groupIndex: groupIndex,
          receiver: {
            user: receiver.user,
            company: receiver.company,
            startDatetimeDifferenceMs: receiver.startDatetimeDifferenceMs,
            status: 'not notified', // reset notified status when moving operator from one group to another
            trucksNumberToReassign: receiver.trucksNumberToReassign || 0,
            trucksAssignedNumber: receiver.trucksAssignedNumber || 0,
          },
        }),
        ...parsedJobAssignments.slice(
          jobAssignmentToMoveIndex + 1,
          parsedJobAssignments.length
        ),
      ];
    }
  });
  return parsedJobAssignments;
}
