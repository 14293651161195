import { getFunctions, httpsCallable } from 'firebase/functions';

import { AssignmentsUpdateOperation } from '@/lib/context/EditSchedulerContext';

import { firebaseApp } from '@/lib/firebase/firebase';

const functions = getFunctions(firebaseApp);

type AddOperatorToJobParams = {
  jobId: string;
  userId: string;
  groupIndex: number | string;
  trucksNumberToReassign: number;
};
export const addOperatorToJob = httpsCallable<AddOperatorToJobParams>(
  functions,
  'addOperatorToJob'
);

type RemoveOperatorFromJobParams = {
  jobId: string;
  jobAssignmentId: string;
};
export const removeOperatorFromJob = httpsCallable<RemoveOperatorFromJobParams>(
  functions,
  'removeOperatorFromJob'
);

export type JobAssignmentsBatchUpdateOperation = Pick<
  AssignmentsUpdateOperation,
  'jobId' | 'type' | 'userId' | 'trucksNumberToReassign' | 'jobAssignmentId'
> & { groupIndex?: string | number };

type JobAssignmentsBatchUpdateParams = {
  operations: JobAssignmentsBatchUpdateOperation[];
};
export const jobAssignmentsBatchUpdate =
  httpsCallable<JobAssignmentsBatchUpdateParams>(
    functions,
    'jobAssignmentsBatchUpdate'
  );
